import React from 'react'

import Divider from 'src/components/Divider'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'

import './style.scss'

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-body">
          <a
            className="contact-item"
            href={`tel:${getTelFormatWithCountryCode(TEL)}`}
            title={TEL}
            id="footer-call"
          >
            <div>
              <img src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/icon_phone.png" alt={TEL} />
            </div>
            <div>
              <p>{TEL}</p>
            </div>
          </a>
          <a
            className="contact-item"
            href="https://lin.ee/o4eIau9?premium=A2"
            target="_blank"
            rel="noopener noreferrer"
            id="footer-line"
            title="@tanjai.co"
          >
            <div>
              <img src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/icon_line.png" alt="@tanjai.co" />
            </div>
            <div>
              <p>@tanjai.co</p>
            </div>
          </a>
          <a
            className="contact-item"
            href="https://www.facebook.com/tanjai.co"
            target="_blank"
            rel="noopener noreferrer"
            id="footer-facebook"
            title="@tanjai.co"
          >
            <div>
              <img
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/icon_fb.png"
                alt="www.facebook.com/tanjai.co"
              />
            </div>
            <div>
              <p>https://www.facebook.com/tanjai.co</p>
            </div>
          </a>
          <div className="contact-item">
            <div>
              <img
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/icon_email.png"
                alt="tanjai.bkk@gmail.com"
              />
            </div>
            <div>
              <p>tanjai.bkk@gmail.com</p>
            </div>
          </div>
          <div className="contact-item">
            <div>
              <img
                src="https://tanjai.s3-ap-southeast-1.amazonaws.com/premium/static/icon_pin.png"
                alt="101 True Digital Park 5th Floor"
              />
            </div>
            <div>
              <p>
                101 True Digital Park 5th Floor, Sukhumvit Rd. Bang Chak, Prakanong, Bangkok 10260
                (สำนักงานใหญ่)
              </p>
            </div>
          </div>
        </div>
        <Divider className="secondary white" />
        <div className="footer-bottom">
          <small>© 2020 BY TANJAI</small>
          <div>
            <img src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/dbd-logo.png" alt="DBD" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
