import React, { Component } from 'react'

// import Pulse from 'react-reveal/Pulse'

// import { getTelFormatWithCountryCode } from 'static/utils'
// import { TEL } from 'static/config/variables'

import './style.scss'

export default class Header extends Component {
  // componentDidMount() {
  //   var navbar = document.getElementById('navbar')
  //   var sticky = navbar.offsetTop

  //   if (window.pageYOffset >= sticky) {
  //     navbar.classList.add('sticky')
  //   } else {
  //     navbar.classList.remove('sticky')
  //   }
  // }

  render() {
    return (
      <header className="header">
        {/* <nav>
          <div className="brand-wrapper">
            <div className="brand-box">
              <div>
                <a href="/">
                  <img src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/logo_v2.png" alt="แทนใจ" />
                </a>
              </div>
            </div>
            <div className="button-box">
              <Pulse>
                <div>
                  <div className="call-button">
                    <img src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/phone.png" alt={TEL} />
                    <a
                      id="header-call-mobile"
                      href={`tel:${getTelFormatWithCountryCode(TEL)}`}
                      title={TEL}
                    >
                      {TEL}
                    </a>
                  </div>
                </div>
                <div className="button">
                  <img
                    src="https://tanjai.s3-ap-southeast-1.amazonaws.com/static/line.png"
                    alt="@tanjai.co"
                  />
                  <a
                    href="https://lin.ee/o4eIau9"
                    target="_blank"
                    rel="noopener noreferrer"
                    id={`navbar-line`}
                    title="@tanjai.co"
                  >
                    <span>สั่งซื้อพวงหรีด</span>
                  </a>
                </div>
              </Pulse>
            </div>
          </div>
        </nav> */}
      </header>
    )
  }
}
